import { MessageDescriptor } from "react-intl";

export const en_strs: Record<string, MessageDescriptor> = {
  aacDescription: {
    id: 'aacOverview',
    defaultMessage:
      "App Acceleration speeds up SaaS applications and file downloads to enhance the user experience " +
      "and boost productivity when connecting through Prisma Access.",
  },
  aacLegalese: {
    id: 'aacLegalese',
    defaultMessage: ''
  },
  aacAdditionalInfo: {
    id: 'aacAddInfo',
    defaultMessage: 'Additional Information'
  },
  aacTooltipText: {
    id: 'aacCfgToolTip',
    defaultMessage:
      "This enables App Acceleration across all Mobile User - Global Protect gateways and " +
      "Remote Network branches on this tenant.  Proceed with caution.",
  },
  aacConfigTitle: {
    id: 'aacCfgTitle',
    defaultMessage: "App Acceleration",
  },
  aacWelcome: {
    id: 'aacWelcome',
    defaultMessage: "Welcome to App Acceleration!",
  },
  aacEnableToggleText: {
    id: 'aacCBM',
    defaultMessage: "Enabled for All Mobile Users -  Global Protect and Remote Networks",
  },
  aacDisableToggleText: {
    id: 'aacCBM',
    defaultMessage: "Disabled for All Mobile Users -  Global Protect and Remote Networks",
  },
  aacSelectPortsMsg: {
    id: 'aacSelectPortsMsg',
    defaultMessage: "Select at least one Port ... - waiting for text from UX",
  },
  aacSSHText: {
    id: 'aacSSHText',
    defaultMessage: "SSH on port 22",
  },
  aacSMBText: {
    id: 'aacSMBText',
    defaultMessage: "SMB on port 445",
  },
  aacHTTPSText: {
    id: 'aacHTTPSText',
    defaultMessage: "HTTPS on port 443",
  },
  aacPortsPlaceHolder: {
    id: 'aacPortPlaceHolder',
    defaultMessage: "Enter TCP ports...",
  },
  aacPortsText: {
    id: 'aacPortsText',
    defaultMessage: "Other TCP ports with encrypted traffic",
  },
  aacPortsExample: {
    id: 'aacPortsExample',
    defaultMessage: "Example Formats: 8443,10443-10445",
  },
  aacPortsTooltip: {
    id: 'portsToolTip',
    defaultMessage: 
      `Enter up to 10 comma-separated ports or port ranges. Port 60443 is not allowed as a port or in a port range.` + 
      `Invalid characters: Letters, spaces, wildcards, and symbols.`
  },
  aacSysNotCapable: {
    id: 'aacNotCapable',
    defaultMessage: 'PAN-OS version 10.2.8 or above is needed to enable App Acceleration. Please contact Global Customer Support to upgrade PAN-OS.'
  },
  aacNoLicense: {
    id: 'aacNoLicense',
    defaultMessage: 'This tenant does not have a license for App Acceleration.  Please contact Palo Alto Networks Sales.'
  },
  aacEnabledSuccess: {
    id: 'aacSuccess',
    defaultMessage: 'App Acceleration successfully enabled.'
  },
  aacDisabledSuccess: {
    id: 'aacSuccess',
    defaultMessage: 'App Acceleration successfully disabled.'
  },
  aacAdemEnabledSuccess: {
    id: 'aacADEMSuccess',
    defaultMessage: 'ADEM changes successfully saved.'
  },
  aacAdemDisabledSuccess: {
    id: 'aacADEMSuccess',
    defaultMessage: 'ADEM successfully disabled.'
  },
  aacAdemChangeSuccess: {
    id: 'aacSuccess',
    defaultMessage: 'ADEM update was successful.'
  },
  aacAdemChangeFailed: {
    id: 'aacSuccess',
    defaultMessage: 'ADEM update failed.'
  },
  aacEnablementInProgress: {
    id: 'aacInProgress',
    defaultMessage: 'App Acceleration will be enabled momentarily. Please stand by.'
  },
  aacEnabledFailure: {
    id: 'aacEnablementFailed',
    defaultMessage: 'App Acceleration could not be enabled. Please contact Global Customer Support'
  },
  aacLicenseExpired: {
    id: 'aacLicenseExpired1',
    defaultMessage: 'The license for App Acceleration has expired. Please contact your account representative to renew your license.'
  },
  aacLicenseExpired2: {
    id: 'aacLicenseExpired2',
    defaultMessage: 'The license for App Acceleration has expired. Please contact your account representative to renew your license.'
  },
  ademLicenseExpired: {
    id: 'ademLicenseExpired',
    defaultMessage: 'Your ADEM evaluation license has expired. Please contact your account manager to continue with App Acceleration tests.'
  },
  aacConfirm: {
    id: 'aacConfirm',
    defaultMessage: 'Confirm'
  },
  aacEnableConfirm: {
    id: 'aacEnableConfirm',
    defaultMessage: 'Confirm Enablement and Ports'
  },
  aacDisableConfirm: {
    id: 'aacDisableConfirm',
    defaultMessage: 'Confirm Disablement'
  },
  aacStepNum1: {
    id: 'aacStepNum1',
    defaultMessage: 'Step 1 of 3'
  },
  aacStepNum2: {
    id: 'aacStepNum2',
    defaultMessage: 'Step 2 of 3'
  },  
  aacStepNum3: {
    id: 'aacStepNum3',
    defaultMessage: 'Step 3 of 3'
  },
  // ADEM Messages
  aacShowAdvanced: {
    id: 'aacShowAdvanced',
    defaultMessage: 'Show Advanced Options'
  },
  aacHideAdvanced: {
    id: 'aacHideAdvanced',
    defaultMessage: 'Hide Advanced Options'
  },
  aacAdemCheck: {
    id: 'aacAdemCheck',
    defaultMessage: 'Allow tests to collect performance metrics for Mobile Users'
  },
  aacAdemCheckTooltip: {
    id: 'ademToolTip',
    defaultMessage: 'Enables synthetic tests that ADEM uses to collect performance metrics using a downloaded, pre-configured file.'
  },
  aacAdemPercentage: {
    id: 'aacAdemPercentage',
    defaultMessage: 'Select Percentage...'
  },
  aacAdemInstruct: {
    id: 'aacAdemInstruct',
    defaultMessage: 'Select the percentage of users (maximum is 20%) to randomly collect performance metrics during the poll period'
  },

  // ---------
  // Disable App Acceleration Modal
  aacDisableAacModalTitle: {
    id: 'aacDisableAacModalTitle',
    defaultMessage: 'Disable App Acceleration'
  },
  aacDisableAacModalMessage: {
    id: 'aacDisableAacModalMessage',
    defaultMessage: 'Are you sure you want to disable App Acceleration?'
  },
  aacDisable: {
    id: 'aacOk',
    defaultMessage: 'Disable'
  },
  aacCancel: {
    id: 'aacCancel',
    defaultMessage: 'Cancel'
  },

  // ---------
  // ADEM Modal Strings
  aacAdemModalTitle: {
    id: 'aacAdemModalTitle',
    defaultMessage: 'App Acceleration Enabled!'
  },
  aacAdemModalIntro:{
    id: 'aacAdemModalIntro',
    defaultMessage: `You've successfully enabled App Acceleration!`
  },
  aacAdemModalPara1a:{
    id: 'aacAdemModalPara1a',
    defaultMessage: 'Visit the '
  },
  aacAdemModalPara1b:{
    id: 'aacAdemModalPara1b',
    defaultMessage: 'Accelerated Applications page'
  },
  aacAdemModalPara1c:{
    id: 'aacAdemModalPara1c',
    defaultMessage: ' to see the App Acceleration speed boost.'
  },
  aacAdemModalPara2:{
    id: 'aacAdemModalPara2',
    defaultMessage: 
      `We've also enabled the collection of metrics through a split tunnel to perform synthetic tests ` + 
      `through ADEM. These tests run in the background on a subset of agents and have no effect on your network speeds or security posture.`
  },
  aacGotIt:{
    id: 'aacGotIt',
    defaultMessage: 'Got It!'
  },
  // ---------
  // ADEM Modal
  // The next 3 are all in one paragraph but 'aacAdemModalPara3b' needs to be bold. 
  aacAdemModalPara3a:{
    id: 'aacAdemModalPara3a1',
    defaultMessage: 'To modify or disable the tests, open '
  },
  aacAdemModalPara3b:{
    id: 'aacAdemModalPara3a2',
    defaultMessage: 'Advanced Options '
  },
  aacAdemModalPara3c:{
    id: 'aacAdemModalPara3a',
    defaultMessage: 'on this page.'
  },


  // generic error messages
  TenantDoesNotExist: { // 403
    id: 'aacErrCBM',
    defaultMessage: "The tenant can not be found. Please contact Global Customer Support.",
  },
  serverDataChanged: {  // 404
    id: 'aacErrCBM',
    defaultMessage: "The tenant cannot be found.  Please contact Global Customer Support.",
  },
  serverError: {    // 500
    id: 'aacErrCBM',
    defaultMessage: "A system error has occurred.  Please contact Global Customer Support.",
  },
};