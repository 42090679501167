export const makeQueryParams = (url: string, params: Record<string, unknown> | string): string => {
    let paramStr = '';
    
    if (typeof params === 'object') {
        const paramKeys = Object.keys(params);
        for (let i = 0, len = paramKeys.length; i < len; i++) {
            if (paramStr.length > 0) {
            paramStr += '&';
            }
            const key = paramKeys[i];
            paramStr += `${key}=${params[key]}`;
        }
    } else if (typeof params === 'string') {
        paramStr = params;
    }

    return `${url}?${paramStr}`;
}

export const NaaURLs = {
    naaHome: '/workflows/app-acceleration',

    naaInsightLink: '/dashboards/sase-health',
    naaTechInfo:    'https://amazon.com',

    serverNaaSettings: '/sse/app_accl/v1/settings',
    serverNaaGetCapabilities: '/sse/app_accl/v1/capabilities',
    serverNaaGetTenantAddonLicenses: '/sse/app_accl/v1/license_addons',
    serverGetAdemLicense: (tid: string) => {return `/api/v3/t/${tid}`; },
    serverAdemAACLInfo: (subTid: string) => { return `/api/v3/st/${subTid}/application-acceleration`; },
}


export default NaaURLs;
