import { getState, getCurrentTsgId } from '@sparky/framework';

import { AppAccelAddonLicense, AddOnInfo } from '../data/queryResultsDefinition';
import { Addon_licenses } from './AppAccelContext'

import { appAccelAppID } from './constants';
import { ZGet } from './backend'
import NaaURLs from './urls';
import Logger from './Logger';

import {rnOnlyLicenseTypes} from './constants'
import { licenseInfoBase, LicEntitlements } from '../data/sparkyDefs';

const LicenseSingleton = (() => {
    let naaLicense: Addon_licenses | undefined = undefined;
    return {
        getInstance: (): Addon_licenses => {
            if (naaLicense === undefined) {
                naaLicense = {items: [], includeAdem: false, in_grace_period: true}
            }

            return naaLicense;
        }
    }
})()

export const getAACLicenseArray = (): Addon_licenses => {
    return LicenseSingleton.getInstance()
}

export async function hasLicense(): Promise<Boolean> {
    let bHasLicense = false;
    let in_grace_period = true;
    try {
        const response = await ZGet(NaaURLs.serverNaaGetTenantAddonLicenses, '');
        const addonLicenses = response as AppAccelAddonLicense;
        if (addonLicenses?.items.length !== 0) {
            bHasLicense = true;

            // save the tenant's licenses
            const licInfo = LicenseSingleton.getInstance();
            licInfo.items = addonLicenses.items;

            // check for RN License
            let includeAdem = false;
            addonLicenses.items.forEach((item: AddOnInfo) => {
                const foundRn = rnOnlyLicenseTypes.findIndex((licType) => { return item.license_type === licType })
                includeAdem = includeAdem || foundRn === -1;  
                in_grace_period = in_grace_period && !!(item?.in_grace_period);
            });
            licInfo.includeAdem = includeAdem
            licInfo.in_grace_period = in_grace_period;
        }
    }
    catch(e: any) {
        bHasLicense = false;
        const licInfo = LicenseSingleton.getInstance();
        licInfo.items = [];
        licInfo.includeAdem = false;
        licInfo.in_grace_period = false;
    }
    return bHasLicense;
}

export async function oldHasLicense() {

    const storeState = getState();
    let validLicense = false;

    const main = storeState.main;

    if (main?.licenseInfo?.entitlements) {
        const entitlements: LicEntitlements = main.licenseInfo.entitlements;
        const addOnLicenses: licenseInfoBase[] = entitlements.addons;
        for (let i=0, len=addOnLicenses.length; i < len; i++) {
            const lic = addOnLicenses[i];
            Logger.info(`license from sparky '${lic.app_id}'`)
            if (lic.app_id === appAccelAppID) {
                Logger.info('found App_Accel license');
                validLicense = true;
                alert('valid license!')
                break;
            }
        }
    }
    Logger.info(`APP_ACCEL has license = ${validLicense}`);

    validLicense = true;        // Temp until licensing actually exists
    return validLicense;
}