export interface RestAPI {
    [param: string]: string | number | boolean;
}

export const RequestMethods: AppAcclMap<string> = {
    get: 'GET',
    post: 'POST',
    delete: 'DELETE',
    put: 'PUT'
}

export interface AddOnInfo {
    license_type: string,
    license_expiration: string,
    in_grace_period: boolean
}

export interface FetchStatus {
    status: {
        error_code?: number,
        error_message?: string,
        http_status?: number,
        http_status_message?: string,
        ok?: boolean,
        stacktrace?: string
    }
}

export interface RestModifiers {
    mimeType?: string;
    noAuthKey?: boolean;
    accept?: string;
}

export interface AppAccelSettingsCommon {
    enabled: boolean,
    ports: string[]
}

export interface AppAccelSettingReq {
    app_acceleration: AppAccelSettingsCommon
}

export interface AppAccelSettings extends FetchStatus {
    item: {
        app_acceleration: AppAccelSettingsCommon
    }
}

export interface AppAccelCapabilities extends FetchStatus {
    item: {
        app_acceleration: {
            capable: boolean
        }
    }
}

export interface AppAccelAddonLicense extends FetchStatus {
    items: AddOnInfo[]
}

export interface AppAccelAdemInfo {
    enabled: boolean,
    activation_percentage: number,
    users: string[],
    groups: string[]
}

export interface ADEMLicenseInfoResp {
    id: string,
    clientTenantId: string,
    dssTenantId: string,
    tsgId: string,
    regionId: number,
    regionUrl: string,
    licenseExpiryTs: number,
}

export type ZRestRequest = 
    | AppAccelSettingReq

export type ZRestResponse = 
    | AppAccelCapabilities 
    | AppAccelSettings
    | AppAccelAddonLicense
    | AppAccelAdemInfo
    | ADEMLicenseInfoResp

export type AppAcclMap<type> = {
    [name: string]: type
}